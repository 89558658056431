import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { H2, P, StyledLink } from "../components/styles/Primitives"

const NFContainer = styled.div`
  padding: 4rem 0 0 0;
  margin: 0 auto;
  max-width: 650px;
`

const NotFoundPage = () => (
  <Layout color="#FEE3EC">
    <NFContainer>
      <Seo title="Lost, are you?" />
   
      <iframe
        src="https://giphy.com/embed/smW5FBep69d3q"
        width="360"
        height="202"
        frameBorder="1"
        class="giphy-embed"
        style={{ borderRadius: `8px`}}
        title="Boromir GIF"
      ></iframe>

      <H2 style={{textAlign: `left`, marginTop: `1rem`}}>Not all who wander are lost. But you are! </H2>
      <P>
        Hello friend! You seem to be lost in the halls on Rivendell sneaked into
        what you belived to be the council of Elrond. Unfortunately this is not
        it.
      </P>
      <br />
      <br />
      <StyledLink to="/">Back to Shire</StyledLink>
    </NFContainer>
  </Layout>
)

export default NotFoundPage
